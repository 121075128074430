/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file
import { weightBreaksSchema } from 'utils/schemas/weightBreakSchema';
import {
	zArrayOfStringOptional,
	zArrayOfStringRequired,
	zAuditable,
	zBoolPreprocessor,
	zEmptyAsNumberPreprocessor,
	zEnsureArrayPreprocessor,
	zMonthPickerRefinement,
	zNumberPreprocessor,
	zNumberRequiredBetween,
} from 'utils/zod/zodValidation';
import z from 'zod';

export type ProductTypeCode =
	| 'bip-airline'
	| 'bip-airline-weekly'
	| 'bip-airline-weekly-plus'
	| 'bip-airline-cdd'
	| 'bip-airline-cdd-weekly'
	| 'bip-airline-cdd-weekly-plus'
	| 'bip-freight-forwarder'
	| 'bip-freight-forwarder-weekly'
	| 'bip-freight-forwarder-weekly-plus'
	| 'bip-freight-forwarder-ndd'
	| 'bip-freight-forwarder-ndd-weekly'
	| 'bip-freight-forwarder-ndd-weekly-plus'
	| 'bip-third-party-revenue'
	| 'bip-third-party-revenue-weekly'
	| 'bip-third-party-weight'
	| 'bip-third-party-weight-weekly'
	| 'market-focus-airline'
	| 'market-focus-airline-cdd'
	| 'market-focus-freight-forwarder'
	| 'market-focus-freight-forwarder-cdd'
	| 'market-focus-third-party'
	| 'market-focus-third-party-cdd'
	| 'airport-financial-data-third-party'
	| 'top-airline'
	| 'top-freight-forwarder'
	| 'ranking-airline'
	| 'ranking-freight-forwarder'
	| 'standard-airline'
	| 'standard-freight-forwarder'
	| 'standard-third-party'
	| 'market-share-airline'
	| 'market-share-freight-forwarder'
	| 'web-tool-airline'
	| 'web-tool-freight-forwarder'
	| 'web-tool-third-party-operational'
	| 'web-tool-third-party-revenue'
	| 'web-tool-iata'
	| 'web-tool-admin'
	| 'cdd-contributor-airline'
	| 'ndd-contributor'
	| 'capacity-market-focus'
	| 'capacity-bip'
	| 'capacity-web-tool'
	| 'capacity-web-tool-iata';

// Product configuration schemas as used by various subscriptions
// These may be extended upon use, such as optional() or single item in arrray
// But the core schema is defined here

const originRequiredSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringRequired('Origin is required')
);
const originOptionalSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringOptional()
);
const destinationOptionalSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringOptional()
);

const freightForwardersOptionalSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringOptional()
);
const nddContributorsOptionalSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringOptional()
);

const currencySchema = z.enum(['USD', 'EUR', 'LOCAL'], {
	required_error: 'Currency is required',
});
const aggregationSchema = z.enum(
	[
		'all-airlines',
		'all-freight-forwarders',
		'all-destination-airports',
		'all-destination-cities',
		'all-destination-countries',
		'all-weightbreaks',
	],
	{
		required_error: 'Aggregation is required',
	}
);
const separatorSchema = z.enum(['semicolon', 'comma', 'tab'], {
	required_error: 'Separator is required',
});
const airlinesSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringRequired('Airlines is required')
);

const freightForwarderSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringRequired('Freight Forwarder is required')
);
const freightForwardersSchema = zArrayOfStringRequired(
	'Freight Forwarders is required'
);
const includeOtherChargesSchema = z
	.preprocess(zBoolPreprocessor, z.boolean())
	.optional();
const yearsOfHistoricalDataSchema = z.preprocess(
	zNumberPreprocessor,
	z.number().min(0).optional()
);
const yearsOfHistoricalDataMandatorySchema = z.preprocess(
	zNumberPreprocessor,
	z.number({ required_error: 'Include Historical Data is required' }).min(0)
);
const timePeriodIntervalSchema = z.enum(['month', 'quarter', 'year'], {
	required_error: 'Time Period Interval is required',
});
const scopeTypeSchema = z.enum(
	['city-city', 'country-country', 'country-world'],
	{
		required_error: 'Scope type is required',
	}
);
const submissionStatusSchema = z.enum(
	['data-testing', 'publishing', 'publishing-paused'],
	{
		required_error: 'Submission Status is required',
	}
);

// BIP CDD Baseline
export const bipCddConfigSchema = z.object({
	origin: originOptionalSchema,
	destination: destinationOptionalSchema,
	currency: currencySchema,
	weightBreaks: weightBreaksSchema.optional(),
	weightBreaksCdd: weightBreaksSchema.optional(),
	includeOtherCharges: includeOtherChargesSchema,
	yearsOfHistoricalData: yearsOfHistoricalDataSchema,
	dataFileSeparator: separatorSchema,
	referenceFileSeparator: separatorSchema,
	iiNetAccountId: z
		.string()
		.regex(/^[cC]\d{3}$/, 'IINET Account ID must be in the format C999'),
});

// BIP Airline CDD
export const bipAirlineCddConfigSchema = z
	.object({
		type: z.literal('bip-airline-cdd'),
		airlines: airlinesSchema,
		groupConsortium: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	})
	.merge(bipCddConfigSchema);

export type BipAirlineCddConfig = z.infer<typeof bipAirlineCddConfigSchema>;

// BIP Airline Weekly CDD
export const bipAirlineCddWeeklyConfigSchema = z
	.object({
		type: z.literal('bip-airline-cdd-weekly'),
		airlines: airlinesSchema,
		groupConsortium: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	})
	.merge(bipCddConfigSchema);

export type BipAirlineCddWeeklyConfig = z.infer<
	typeof bipAirlineCddWeeklyConfigSchema
>;

// BIP Airline Weekly Plus CDD
export const bipAirlineCddWeeklyPlusConfigSchema = z
	.object({
		type: z.literal('bip-airline-cdd-weekly-plus'),
		airlines: airlinesSchema,
		groupConsortium: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	})
	.merge(bipCddConfigSchema);

export type BipAirlineCddWeeklyPlusConfig = z.infer<
	typeof bipAirlineCddWeeklyPlusConfigSchema
>;

// BIP Freight Forwarder NDD
export const bipFreightForwarderNddConfigSchema = z
	.object({
		type: z.literal('bip-freight-forwarder-ndd'),
		freightForwarders: freightForwardersOptionalSchema,
		nddContributors: nddContributorsOptionalSchema,
	})
	.merge(bipCddConfigSchema);

export type BipFreightForwarderNddConfig = z.infer<
	typeof bipFreightForwarderNddConfigSchema
>;

// BIP Freight Forwarder NDD Weekly
export const bipFreightForwarderNddWeeklyConfigSchema = z
	.object({
		type: z.literal('bip-freight-forwarder-ndd-weekly'),
		freightForwarders: freightForwardersOptionalSchema,
		nddContributors: nddContributorsOptionalSchema,
	})
	.merge(bipCddConfigSchema);

export type BipFreightForwarderNddWeeklyConfig = z.infer<
	typeof bipFreightForwarderNddWeeklyConfigSchema
>;

// BIP Freight Forwarder NDD Weekly Plus
export const bipFreightForwarderNddWeeklyPlusConfigSchema = z
	.object({
		type: z.literal('bip-freight-forwarder-ndd-weekly-plus'),
		freightForwarders: freightForwardersOptionalSchema,
		nddContributors: nddContributorsOptionalSchema,
	})
	.merge(bipCddConfigSchema);

export type BipFreightForwarderNddWeeklyPlusConfig = z.infer<
	typeof bipFreightForwarderNddWeeklyPlusConfigSchema
>;

// BIP Baseline
export const bipConfigSchema = z.object({
	origin: originOptionalSchema,
	destination: destinationOptionalSchema,
	currency: currencySchema,
	weightBreaks: weightBreaksSchema.optional(),
	includeOtherCharges: includeOtherChargesSchema,
	yearsOfHistoricalData: yearsOfHistoricalDataSchema,
	dataFileSeparator: separatorSchema,
	referenceFileSeparator: separatorSchema,
	iiNetAccountId: z
		.string()
		.regex(/^[cC]\d{3}$/, 'IINET Account ID must be in the format C999'),
});

// BIP Airline
export const bipAirlineConfigSchema = z
	.object({
		type: z.literal('bip-airline'),
		airlines: airlinesSchema,
		groupConsortium: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	})
	.merge(bipConfigSchema);

export type BipAirlineConfig = z.infer<typeof bipAirlineConfigSchema>;

// BIP Airline Weekly
export const bipAirlineWeeklyConfigSchema = z
	.object({
		type: z.literal('bip-airline-weekly'),
		airlines: airlinesSchema,
		groupConsortium: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	})
	.merge(bipConfigSchema);

export type BipAirlineWeeklyConfig = z.infer<
	typeof bipAirlineWeeklyConfigSchema
>;

// BIP Airline Weekly Plus
export const bipAirlineWeeklyPlusConfigSchema = z
	.object({
		type: z.literal('bip-airline-weekly-plus'),
		airlines: airlinesSchema,
		groupConsortium: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	})
	.merge(bipConfigSchema);

export type BipAirlineWeeklyPlusConfig = z.infer<
	typeof bipAirlineWeeklyPlusConfigSchema
>;

// BIP Freight Forwarder
export const bipFreightForwarderConfigSchema = z
	.object({
		type: z.literal('bip-freight-forwarder'),
		freightForwarders: freightForwardersSchema,
		isSpecialBipReport: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	})
	.merge(bipConfigSchema);

export type BipFreightForwarderConfig = z.infer<
	typeof bipFreightForwarderConfigSchema
>;

// BIP Freight Forwarder Weekly
export const bipFreightForwarderWeeklyConfigSchema = z
	.object({
		type: z.literal('bip-freight-forwarder-weekly'),
		freightForwarders: freightForwardersSchema,
	})
	.merge(bipConfigSchema);

export type BipFreightForwarderWeeklyConfig = z.infer<
	typeof bipFreightForwarderWeeklyConfigSchema
>;

// BIP Freight Forwarder Weekly Plus
export const bipFreightForwarderWeeklyPlusConfigSchema = z
	.object({
		type: z.literal('bip-freight-forwarder-weekly-plus'),
		freightForwarders: freightForwardersSchema,
	})
	.merge(bipConfigSchema);

export type BipFreightForwarderWeeklyPlusConfig = z.infer<
	typeof bipFreightForwarderWeeklyPlusConfigSchema
>;

// BIP Third Party Revenue
export const bipThirdPartyRevenueSchema = z
	.object({
		type: z.literal('bip-third-party-revenue'),
	})
	.merge(bipConfigSchema);

export type BipThirdPartyRevenueSchema = z.infer<
	typeof bipThirdPartyRevenueSchema
>;

// BIP Third Party Revenue Weekly
export const bipThirdPartyRevenueWeeklySchema = z
	.object({
		type: z.literal('bip-third-party-revenue-weekly'),
	})
	.merge(bipConfigSchema);

export type BipThirdPartyRevenueWeeklySchema = z.infer<
	typeof bipThirdPartyRevenueWeeklySchema
>;

// BIP Third Party Weight
export const bipThirdPartyWeightSchema = z
	.object({
		type: z.literal('bip-third-party-weight'),
	})
	.merge(bipConfigSchema);

export type BipThirdPartyWeightSchema = z.infer<
	typeof bipThirdPartyWeightSchema
>;

// BIP Third Party Weight Weekly
export const bipThirdPartyWeightWeeklySchema = z
	.object({
		type: z.literal('bip-third-party-weight-weekly'),
	})
	.merge(bipConfigSchema);

export type BipThirdPartyWeightWeeklySchema = z.infer<
	typeof bipThirdPartyWeightWeeklySchema
>;

// Capacity BIP Baseline
export const capacityBipConfigBaseSchema = z.object({
	origin: originOptionalSchema,
	destination: destinationOptionalSchema,
	yearsOfHistoricalData: yearsOfHistoricalDataSchema,
	dataFileSeparator: separatorSchema,
	referenceFileSeparator: separatorSchema,
	iiNetAccountId: z
		.string()
		.regex(/^[cC]\d{3}$/, 'IINET Account ID must be in the format C999'),
});

export const capacityBipSchema = z
	.object({
		type: z.literal('capacity-bip'),
	})
	.merge(capacityBipConfigBaseSchema);

export type CapacityBipSchema = z.infer<typeof capacityBipSchema>;

// Market Focus Baseline
export const mfConfigSchema = z.object({
	origin: originRequiredSchema,
	yearsOfHistoricalData: yearsOfHistoricalDataSchema,
});

// Market Focus Airline
export const marketFocusAirlineConfigSchema = z
	.object({
		type: z.literal('market-focus-airline'),
		airlines: airlinesSchema,
	})
	.merge(mfConfigSchema);

export type MfAirlineConfig = z.infer<typeof marketFocusAirlineConfigSchema>;

// Market Focus Freight Forwarder
export const marketFocusFreightForwarderConfigSchema = z
	.object({
		type: z.literal('market-focus-freight-forwarder'),
		freightForwarders: freightForwardersSchema,
	})
	.merge(mfConfigSchema);

export type MfFreightForwarderConfig = z.infer<
	typeof marketFocusFreightForwarderConfigSchema
>;

// Market Focus Third Party
export const marketFocusThirdPartySchema = z
	.object({
		type: z.literal('market-focus-third-party'),
	})
	.merge(mfConfigSchema);

export type MfThirdPartySchema = z.infer<typeof marketFocusThirdPartySchema>;

// Market Focus CDD Baseline
export const mfCddConfigSchema = z.object({
	origin: originRequiredSchema,
	yearsOfHistoricalData: yearsOfHistoricalDataSchema,
});

// Market Focus Airline CDD
export const marketFocusAirlineCddConfigSchema = z
	.object({
		type: z.literal('market-focus-airline-cdd'),
		airlines: airlinesSchema,
	})
	.merge(mfCddConfigSchema);

export type MfAirlineCddConfig = z.infer<
	typeof marketFocusAirlineCddConfigSchema
>;

// Market Focus Freight Forwarder CDD
export const marketFocusFreightForwarderCddConfigSchema = z
	.object({
		type: z.literal('market-focus-freight-forwarder-cdd'),
		freightForwarders: freightForwardersSchema,
	})
	.merge(mfCddConfigSchema);

export type MfFreightForwarderCddConfig = z.infer<
	typeof marketFocusFreightForwarderCddConfigSchema
>;

// Market Focus Third Party CDD
export const marketFocusThirdPartyCddSchema = z
	.object({
		type: z.literal('market-focus-third-party-cdd'),
	})
	.merge(mfCddConfigSchema);

export type MfThirdPartyCddSchema = z.infer<
	typeof marketFocusThirdPartyCddSchema
>;

// Capacity Market Focus
export const capacityMarketFocusSchema = z
	.object({
		type: z.literal('capacity-market-focus'),
	})
	.merge(mfConfigSchema);

export type CapacityMfSchema = z.infer<typeof capacityMarketFocusSchema>;

// Airport Financial Data
export const airportFinancialDataThirdPartySchema = z
	.object({
		type: z.literal('airport-financial-data-third-party'),
		origin: originRequiredSchema,
		currency: currencySchema,
	})
	.merge(mfConfigSchema);

export type AfdThirdPartySchema = z.infer<
	typeof airportFinancialDataThirdPartySchema
>;

// Top Baseline
export const topConfigSchema = z.object({
	origin: originRequiredSchema,
	yearsOfHistoricalData: yearsOfHistoricalDataSchema,
});

// Top Airline
export const topAirlineConfigSchema = z
	.object({
		type: z.literal('top-airline'),
		airlines: airlinesSchema,
	})
	.merge(topConfigSchema);

export type TopAirlineConfig = z.infer<typeof topAirlineConfigSchema>;

// Top Freight Forwarder
export const topFreightForwarderConfigSchema = z
	.object({
		type: z.literal('top-freight-forwarder'),
		freightForwarders: freightForwardersSchema,
	})
	.merge(topConfigSchema);

export type TopFreightForwarderConfig = z.infer<
	typeof topFreightForwarderConfigSchema
>;

// Ranking Baseline
export const rankingConfigSchema = z.object({
	origin: originRequiredSchema,
});

// Ranking Airline
export const rankingAirlineConfigSchema = z
	.object({
		type: z.literal('ranking-airline'),
		yearsOfHistoricalData: yearsOfHistoricalDataMandatorySchema,
	})
	.merge(rankingConfigSchema);

export type RankingAirlineConfig = z.infer<typeof rankingAirlineConfigSchema>;

// Ranking Freight Forwarder
export const rankingFreightForwarderConfigSchema = z
	.object({
		type: z.literal('ranking-freight-forwarder'),
		timePeriodInterval: timePeriodIntervalSchema,
	})
	.merge(rankingConfigSchema);

export type RankingFreightForwarderConfig = z.infer<
	typeof rankingFreightForwarderConfigSchema
>;

// Standard Report Baseline
export const standardReportConfigSchema = z.object({
	origin: originRequiredSchema,
	destination: destinationOptionalSchema,
	currency: currencySchema,
	yearsOfHistoricalData: yearsOfHistoricalDataSchema,
	aggregation: aggregationSchema,
});

// Standard Report Airline
export const standardAirlineConfigSchema = z
	.object({
		type: z.literal('standard-airline'),
		airlines: airlinesSchema,
	})
	.merge(standardReportConfigSchema);

export type StandardReportAirlineConfig = z.infer<
	typeof standardAirlineConfigSchema
>;

// Standard Report Freight Forwarder
export const standardFreightForwarderConfigSchema = z
	.object({
		type: z.literal('standard-freight-forwarder'),
		freightForwarders: freightForwarderSchema,
	})
	.merge(standardReportConfigSchema);

export type StandardReportFreightForwarderConfig = z.infer<
	typeof standardFreightForwarderConfigSchema
>;

// Standard Report Third Party
export const standardThirdPartySchema = z
	.object({
		type: z.literal('standard-third-party'),
	})
	.merge(standardReportConfigSchema);

export type StandardReportThirdPartySchema = z.infer<
	typeof standardThirdPartySchema
>;

// Market Share Baseline
export const marketShareConfigSchema = z.object({
	origin: originOptionalSchema,
	destination: destinationOptionalSchema,
	origin2: originOptionalSchema,
	destination2: destinationOptionalSchema,
	origin3: originOptionalSchema,
	destination3: destinationOptionalSchema,
	lengthOfRanking: z.preprocess(
		zEmptyAsNumberPreprocessor,
		z
			.number({ required_error: 'Length of ranking is required' })
			.min(1, 'Length of ranking must be above 1')
	),
	minimumChargeableCompetitiveWeight: z.preprocess(
		zEmptyAsNumberPreprocessor,
		z
			.number({
				required_error:
					'Minimum chargeable competitive weight (kg/year) is required',
			})
			.min(1, 'Minimum chargeable competitive weight (kg/year) must be above 1')
	),
	scopeType: scopeTypeSchema,
});

// Market Share Airline
export const marketShareAirlineConfigSchema = z
	.object({
		type: z.literal('market-share-airline'),
		airlines: airlinesSchema,
	})
	.merge(marketShareConfigSchema);

export type MarketShareAirlineConfig = z.infer<
	typeof marketShareAirlineConfigSchema
>;

// Market Share Freight Forwarder
export const marketShareFreightForwarderConfigSchema = z
	.object({
		type: z.literal('market-share-freight-forwarder'),
		freightForwarders: zArrayOfStringOptional(),
	})
	.merge(marketShareConfigSchema);

export type MarketShareFreightForwarderConfig = z.infer<
	typeof marketShareFreightForwarderConfigSchema
>;

export type HistoricalDataSettings = {
	type: 'dropdown' | 'checkbox' | 'not-available';
	numYearsAvailable: number;
	defaultNumYears?: number;
	label: string;
};

// Web Tool Baseline
const webToolDataGroupSchema = z.object({
	origin: originOptionalSchema,
	destination: destinationOptionalSchema,
	startPeriod: monthPickerSchema('Start period'),
	endPeriod: monthPickerSchema('End period'),
	includeReverseRoutes: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	// Not used in the backend, but allows us to ensure correct validation happens on the front end
	id: z.string().optional(),
});

export const webToolConfigBaseSchema = z.object({
	webToolDataSource: z.enum(
		['cass', 'cass-and-cdd', 'cass-cdd-revenue-only', 'ndd'],
		{
			required_error: 'Data Source is required',
		}
	),
	webToolHiddenFields: zArrayOfStringOptional(),
});

// Web Tool Airline/GSA
export const webToolAirlineConfigSchema = z
	.object({
		type: z.literal('web-tool-airline'),
		webToolDataGroups: z.array(
			z
				.object({
					airlines: airlinesSchema,
				})
				.merge(webToolDataGroupSchema)
				.superRefine(zMonthPickerRefinement('startPeriod', 'endPeriod')),
			{ required_error: 'At least one group is required' }
		),
		webToolDataSubscription: z.enum(['monthly', 'weekly', 'weekly-plus'], {
			required_error: 'Data Subscription is required',
		}),
		marketBreakdown: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	})
	.merge(webToolConfigBaseSchema);

export type WebtoolAirlineConfig = z.infer<typeof webToolAirlineConfigSchema>;

// Web Tool Freight Forwarder
export const webToolFreightForwarderConfigSchema = z
	.object({
		type: z.literal('web-tool-freight-forwarder'),
		webToolDataGroups: z.array(
			z
				.object({
					freightForwarders: freightForwardersOptionalSchema,
					nddContributors: nddContributorsOptionalSchema,
				})
				.merge(webToolDataGroupSchema)
				.superRefine(zMonthPickerRefinement('startPeriod', 'endPeriod'))
		),
		webToolDataSubscription: z.enum(['monthly', 'weekly', 'weekly-plus'], {
			required_error: 'Data Subscription is required',
		}),
		marketBreakdown: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	})
	.merge(webToolConfigBaseSchema);

export type WebtoolFreightForwarderConfig = z.infer<
	typeof webToolFreightForwarderConfigSchema
>;

// Web Tool Third-Party (Operational)
export const webToolThirdPartyOperationalConfigSchema = z
	.object({
		type: z.literal('web-tool-third-party-operational'),
		webToolDataGroups: z.array(
			webToolDataGroupSchema
				.extend({})
				.superRefine(zMonthPickerRefinement('startPeriod', 'endPeriod')),
			{
				required_error: 'At least one group is required',
			}
		),
		webToolDataSubscription: z.enum(['monthly', 'weekly', 'weekly-plus'], {
			required_error: 'Data Subscription is required',
		}),
	})
	.merge(webToolConfigBaseSchema);

export type WebtoolThirdPartyOperationalConfig = z.infer<
	typeof webToolThirdPartyOperationalConfigSchema
>;

// Web Tool Third-Party (Revenue)
export const webToolThirdPartyRevenueConfigSchema = z
	.object({
		type: z.literal('web-tool-third-party-revenue'),
		webToolDataGroups: z.array(
			webToolDataGroupSchema
				.extend({})
				.superRefine(zMonthPickerRefinement('startPeriod', 'endPeriod')),
			{
				required_error: 'At least one group is required',
			}
		),
		webToolDataSubscription: z.enum(['monthly', 'weekly', 'weekly-plus'], {
			required_error: 'Data Subscription is required',
		}),
	})
	.merge(webToolConfigBaseSchema);

export type WebtoolThirdPartyRevenueConfig = z.infer<
	typeof webToolThirdPartyRevenueConfigSchema
>;

// Web Tool IATA
export const webToolIataConfigSchema = z
	.object({
		type: z.literal('web-tool-iata'),
		webToolDataGroups: z.array(
			webToolDataGroupSchema
				.extend({})
				.superRefine(zMonthPickerRefinement('startPeriod', 'endPeriod')),
			{
				required_error: 'At least one group is required',
			}
		),
		webToolDataSubscription: z.enum(
			['monthly', 'weekly', 'weekly-plus', 'live'],
			{
				required_error: 'Data Subscription is required',
			}
		),
		marketBreakdown: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	})
	.merge(webToolConfigBaseSchema);

export type webToolIataConfig = z.infer<typeof webToolIataConfigSchema>;

// Web Tool Admin
export const webToolAdminConfigSchema = z
	.object({
		type: z.literal('web-tool-admin'),
		webToolDataGroups: z.array(
			webToolDataGroupSchema
				.extend({})
				.superRefine(zMonthPickerRefinement('startPeriod', 'endPeriod')),
			{
				required_error: 'At least one group is required',
			}
		),
		webToolDataSubscription: z.enum(['monthly', 'weekly'], {
			required_error: 'Data Subscription is required',
		}),
		marketBreakdown: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	})
	.merge(webToolConfigBaseSchema);

export type webToolAdminConfig = z.infer<typeof webToolAdminConfigSchema>;

// Capacity Web Tool baseline
export const capacityWebToolBaseConfigSchema = z.object({
	webToolDataGroups: z.array(
		webToolDataGroupSchema
			.extend({})
			.superRefine(zMonthPickerRefinement('startPeriod', 'endPeriod')),
		{
			required_error: 'At least one group is required',
		}
	),
	webToolHiddenFields: zArrayOfStringOptional(),
});

// Capacity Web Tool
export const capacityWebToolConfigSchema = z
	.object({
		type: z.literal('capacity-web-tool'),
	})
	.merge(capacityWebToolBaseConfigSchema);

export type CapacityWebtoolConfig = z.infer<typeof capacityWebToolConfigSchema>;

export const capacityWebToolIataConfigSchema = z
	.object({
		type: z.literal('capacity-web-tool-iata'),
	})
	.merge(capacityWebToolBaseConfigSchema);

export type CapacityWebtoolIataConfig = z.infer<
	typeof capacityWebToolIataConfigSchema
>;

// CDD Contributor Baseline
export const cddContributorConfigSchema = z.object({
	submissionStatus: submissionStatusSchema,
});

// CDD Contributor Airline/GSA
export const cddContributorAirlineConfigSchema = z
	.object({
		type: z.literal('cdd-contributor-airline'),
		airlines: airlinesSchema,
		gsaCode: z.string().optional(),
		gsaName: z.string().optional(),
	})
	.merge(cddContributorConfigSchema);

export type CddContributorAirlineConfig = z.infer<
	typeof cddContributorAirlineConfigSchema
>;

// NDD Contributor
export const nddContributorConfigSchema = z.object({
	type: z.literal('ndd-contributor'),
	webToolDataGroups: z.array(
		webToolDataGroupSchema
			.extend({})
			.superRefine(zMonthPickerRefinement('startPeriod', 'endPeriod')),
		{
			required_error: 'At least one group is required',
		}
	),
	submissionStatus: submissionStatusSchema,
	contributorCode: z
		.string()
		.regex(
			/^[a-zA-Z0-9]{4}$/,
			'Contributor Code must be 4 alphanumeric characters long'
		),
	contributorName: z
		.string()
		.max(75, 'Contributor Name must not exceed 75 characters.')
		.min(3, 'Contributor Name must consist of 3 or more characters.'),
});

export type NddContributorConfig = z.infer<typeof nddContributorConfigSchema>;

// 2. Add it to this list so we have the descriminated union of product schemas
export const productConfigDetailsSchema = z
	.discriminatedUnion(
		'type',
		[
			bipAirlineConfigSchema,
			bipAirlineWeeklyConfigSchema,
			bipAirlineWeeklyPlusConfigSchema,
			bipAirlineCddConfigSchema,
			bipAirlineCddWeeklyConfigSchema,
			bipAirlineCddWeeklyPlusConfigSchema,
			bipFreightForwarderConfigSchema,
			bipFreightForwarderWeeklyConfigSchema,
			bipFreightForwarderWeeklyPlusConfigSchema,
			bipFreightForwarderNddConfigSchema,
			bipFreightForwarderNddWeeklyConfigSchema,
			bipFreightForwarderNddWeeklyPlusConfigSchema,
			bipThirdPartyRevenueSchema,
			bipThirdPartyRevenueWeeklySchema,
			bipThirdPartyWeightSchema,
			bipThirdPartyWeightWeeklySchema,
			marketFocusFreightForwarderConfigSchema,
			marketFocusFreightForwarderCddConfigSchema,
			marketFocusAirlineConfigSchema,
			marketFocusAirlineCddConfigSchema,
			marketFocusThirdPartySchema,
			marketFocusThirdPartyCddSchema,
			airportFinancialDataThirdPartySchema,
			topAirlineConfigSchema,
			topFreightForwarderConfigSchema,
			rankingAirlineConfigSchema,
			rankingFreightForwarderConfigSchema,
			standardFreightForwarderConfigSchema,
			standardAirlineConfigSchema,
			standardThirdPartySchema,
			marketShareAirlineConfigSchema,
			marketShareFreightForwarderConfigSchema,
			webToolAirlineConfigSchema,
			webToolFreightForwarderConfigSchema,
			webToolThirdPartyOperationalConfigSchema,
			webToolThirdPartyRevenueConfigSchema,
			webToolIataConfigSchema,
			webToolAdminConfigSchema,
			cddContributorAirlineConfigSchema,
			nddContributorConfigSchema,
			capacityMarketFocusSchema,
			capacityBipSchema,
			capacityWebToolConfigSchema,
			capacityWebToolIataConfigSchema,
		],
		{ required_error: 'Product configuration required' }
	)
	.superRefine((data, ctx) => {
		if (data.type === 'cdd-contributor-airline') {
			if (!data.gsaCode && data.gsaName) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['gsaCode'],
					message: 'GSA Code is required when GSA Name is provided',
					fatal: true,
				});
			}
			if (data.gsaCode && !data.gsaName) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['gsaName'],
					message: 'GSA Name is required when GSA Code is provided',
					fatal: true,
				});
			}
			if (data.gsaCode && data.gsaCode.length !== 3) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['gsaCode'],
					message: 'GSA Code must be 3 characters long',
					fatal: true,
				});
			}
			if (data.gsaCode && !/^[a-zA-Z]+$/.test(data.gsaCode)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['gsaCode'],
					message: 'GSA Code must only contain letters A-Z',
					fatal: true,
				});
			}
		}
	})
	.superRefine((data, ctx) => {
		if (
			data.type === 'bip-freight-forwarder-ndd' ||
			data.type === 'bip-freight-forwarder-ndd-weekly' ||
			data.type === 'bip-freight-forwarder-ndd-weekly-plus'
		) {
			if (!data.freightForwarders?.length && !data.nddContributors?.length) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['freightForwarders'],
					message:
						'At least one freight fowarder or NDD contributor is required',
					fatal: true,
				});
			}
		}
	})
	.superRefine((data, ctx) => {
		if (
			data.type === 'bip-freight-forwarder' ||
			data.type === 'bip-freight-forwarder-weekly' ||
			data.type === 'bip-freight-forwarder-weekly-plus' ||
			data.type === 'bip-freight-forwarder-ndd-weekly' ||
			data.type === 'bip-freight-forwarder-ndd-weekly-plus' ||
			data.type === 'bip-airline' ||
			data.type === 'bip-airline-weekly' ||
			data.type === 'bip-airline-weekly-plus' ||
			data.type === 'bip-third-party-revenue' ||
			data.type === 'bip-third-party-weight' ||
			data.type === 'bip-airline-cdd' ||
			data.type === 'bip-airline-cdd-weekly' ||
			data.type === 'bip-airline-cdd-weekly-plus' ||
			data.type === 'bip-freight-forwarder-ndd'
		) {
			if (
				(data.origin === undefined && data.destination === undefined) ||
				(data.origin?.length === 0 && data.destination?.length === 0)
			) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['origin'],
					message: 'At least one origin or destination country is required',
					fatal: true,
				});
			}
		}

		if (
			data.type === 'market-share-airline' ||
			data.type === 'market-share-freight-forwarder'
		) {
			const originAndDestinationEmpty =
				(data.origin === undefined || data.origin?.length === 0) &&
				(data.destination === undefined || data.destination?.length === 0);

			if (originAndDestinationEmpty) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['origin'],
					message: 'At least one origin or destination country is required',
					fatal: true,
				});
			}

			const origin2Empty =
				data.origin2 === undefined || data.origin2.length === 0;
			const destination2Empty =
				data.destination2 === undefined || data.destination2.length === 0;
			const origin3Empty =
				data.origin3 === undefined || data.origin3.length === 0;
			const destination3Empty =
				data.destination3 === undefined || data.destination3.length === 0;

			if (originAndDestinationEmpty && !origin2Empty) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['origin2'],
					message:
						'Please provide either Origin or Destination before using Origin 2',
					fatal: true,
				});
			}

			if (originAndDestinationEmpty && !destination2Empty) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['destination2'],
					message:
						'Please provide either Origin or Destination before using Destination 2',
					fatal: true,
				});
			}

			if (origin2Empty && destination2Empty && !origin3Empty) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['origin3'],
					message:
						'Please provide either Origin 2 or Destination 2 before using Origin 3',
					fatal: true,
				});
			}

			if (origin2Empty && destination2Empty && !destination3Empty) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['destination3'],
					message:
						'Please provide either Origin 2 or Destination 2 before using Destination 3',
					fatal: true,
				});
			}
		}
	});

export function monthPickerSchema(fieldName: string) {
	return z.object(
		{
			year: z.preprocess(
				zNumberPreprocessor,
				zNumberRequiredBetween(`${fieldName} year is required`, 2010, 2050)
			),
			month: z.preprocess(
				zNumberPreprocessor,
				zNumberRequiredBetween(`${fieldName} month is required`, 1, 12)
			),
		},
		{ required_error: `${fieldName} is required` }
	);
}

export const subscriptionSchema = z.object({
	subscriptionId: z.string(),
	subscriptionNumber: z
		.string()
		.regex(
			/^$|S\d{5,7}$/,
			'Subscription Number must be empty or in the format S99999 - S9999999'
		),
	name: z.string().max(100, 'Name must not be more than 100 characters long'),
	customerId: z
		.string({ required_error: 'Customer is required' })
		.min(1, 'Customer is required'),
	productId: z
		.string({ required_error: 'Product is required' })
		.min(1, 'Product is required'),
	subscriptionDeliveryStatusCode: z.string({
		required_error: 'Delivery Status is required',
	}),
	productConfiguration: productConfigDetailsSchema,
	startPeriod: monthPickerSchema('Start period'),
	endPeriod: monthPickerSchema('End period'),
	numberOfLicenses: z.preprocess(
		zNumberPreprocessor,
		z.number().min(0).optional()
	),
});

export type SubscriptionModel = z.infer<typeof subscriptionSchema>;

export const createSubscriptionSchema = subscriptionSchema
	.pick({
		name: true,
		subscriptionNumber: true,
		customerId: true,
		productId: true,
		subscriptionDeliveryStatusCode: true,
		startPeriod: true,
		endPeriod: true,
		numberOfLicenses: true,
		productConfiguration: true,
	})
	.merge(zAuditable)
	.superRefine(zMonthPickerRefinement('startPeriod', 'endPeriod'));

export type CreateSubscriptionModel = z.infer<typeof createSubscriptionSchema>;

export const updateSubscriptionSchema = subscriptionSchema
	.pick({
		name: true,
		subscriptionDeliveryStatusCode: true,
		startPeriod: true,
		endPeriod: true,
		numberOfLicenses: true,
		productConfiguration: true,
	})
	.merge(zAuditable)
	.superRefine(zMonthPickerRefinement('startPeriod', 'endPeriod'));

export type UpdateSubscriptionModel = z.infer<typeof updateSubscriptionSchema>;
